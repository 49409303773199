import * as React from 'react';
import Link from 'gatsby-link';
import { Helmet } from 'react-helmet';

const InfoPage = () => (
    <div>
        <Helmet>
            <title>Stef van Dijk | Info</title>
        </Helmet>
        <p>Such Info, many things</p>
    </div>
);

export default InfoPage;
